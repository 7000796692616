import React from 'react'
import Layout from '../components/layout'

const Strica = () => (
  <Layout title='Privacy Policy | Strica'>
    <h1>PRIVACY POLICY</h1>

    <p>
      This privacy policy has been compiled to better serve those who are
      concerned with how their Personally Identifiable Information (PII) is
      being used online. PII, as used in UK privacy law and information
      security, is information that can be used on its own or with other
      information to identify, contact or locate a single person, or to identify
      an individual in context. Please read our privacy policy carefully to get
      clear understanding of how we collect, use, protect or otherwise handle
      your Personally Identifiable Information in accordance with our
      application (app).
    </p>

    <h2>Registry</h2>

    <p>We do not collect information from when you use our app.</p>

    <p>
      Our app is scanned on a regular basis for security holes and known
      vulnerabilities in order to make your visit to our app as safe as
      possible.
    </p>

    <h2>Cookies</h2>

    <p>We use cookies to:</p>

    <ul>
      <li>Understand and save user’s preferences for future visits.</li>
      <li>Keep track of advertisements.</li>
    </ul>

    <p>
      Cookies are small files that a site, app or its service provider
      transfers to your computer’s hard drive through your Web browser (if you
      allow) that enables the service system to recognize your browser and
      capture and remember certain information.
    </p>

    <h2>Third Party</h2>

    <p>
      We do not sell, trade, or transfer to outside parties your personally
      identifiable information. Occasionally, at our discretion, we may include
      or offer third party products or services on our app. These third party
      sites or apps have separate and independent privacy policies. We have no
      responsibility or liability for the content and activities of these
      linked sites or apps.
    </p>

    <h2>Changes</h2>

    <p>
      We reserve the right to modify or replace these Privacy Policy at any
      time. Users will be notified of any privacy changes on our Privacy Policy
      Page. If a data breach occur, we will notify the users via in app
      notification within 7 days.
    </p>

    <h2>Contact Us</h2>

    <p>
      If you have any questions about these Privacy Policy, please{' '}
      <a href='mailto:jbonigomes@yahoo.com.br'>contact us</a>.
    </p>
  </Layout>
)

export default Strica
